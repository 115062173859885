
import { Options, Vue } from "vue-class-component";
import { Repo } from "@/backend/types";
import { LanguageLogo } from "@/backend/Language";

@Options({
  props: {
    repo: Object,
    index: Number,
  },
})
export default class RepoCard extends Vue {
  repo!: Repo;
  index!: number;
  LanguageLogo = LanguageLogo;

  openUrl(url: string): void {
    window.open(url, "_blank");
  }
}
