
import { Options, Vue } from "vue-class-component";
import { User } from "@/backend/types";

@Options({
  props: {
    user: Object,
    fork_count: Number,
    archived_count: Number,
  },
})
export default class UserCard extends Vue {
  user!: User;
  fork_count!: number;
  archived_count!: number;

  openUrl(url: string): void {
    window.open(url, "_blank");
  }
}
